import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isJSDocThisTag } from 'typescript';
import { AuthNewService } from '../../../service/auth-new.service';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-user-forgot-password',
  templateUrl: './user-forgot-password.component.html',
  styleUrls: ['./user-forgot-password.component.scss']
})
export class UserForgotPasswordComponent implements OnInit {

  forgetPasswordForm: FormGroup;
  forgetPasswordConfirmForm: FormGroup;
  ChangePasswordConfirmForm: FormGroup;
  codeRecieved = false;
  submitted = false;
  errorMessageForgot;
  errorMessageConfirm;
  username;
  hide = true;
  passwordPattern = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?!.*\\s)(?=.*?[#?!@$%^&*-]).{8,}$'
  passwordtype: string;
  validatePasswordURL: string = environment.serverUrl + '/validate-p-hash';

  constructor(private fb: FormBuilder, private authservice: AuthNewService, private router: Router,
    private http: HttpClient,
    private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedroute.queryParams.subscribe(params => {
      this.passwordtype = params['passtype'];
    });

    this.forgetPasswordForm = this.fb.group({
      username: ['', Validators.required]
    })

    this.forgetPasswordConfirmForm = this.fb.group({
      code: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern(this.passwordPattern)]]
    })

    this.ChangePasswordConfirmForm = this.fb.group({
      code: ['', Validators.required],
      oldpassword: ['', Validators.required],
      newpassword: ['', [Validators.required, Validators.pattern(this.passwordPattern)]],
      confirmnewpassword: ['', [Validators.required, Validators.pattern(this.passwordPattern)]]
    })
  }
  get formControls() { return this.forgetPasswordForm.controls; }
  get formControls1() { return this.forgetPasswordConfirmForm.controls; }
  get formControls2() { return this.ChangePasswordConfirmForm.controls; }

  UserForgetPassword() {
    const name = this.forgetPasswordForm.value.username;
    this.username = name;
    this.authservice.forgetPassword(name);
    setTimeout(() => {
      this.authservice.forgotPasswordCode.subscribe((res) => {

        if (res === true) {
          this.codeRecieved = true;
        } else {
          this.errorMessageForgot = 'No user found.'
        }
      })
    }, 3000)
  }

  UserForgetPasswordConfirm() {
    const username = this.forgetPasswordForm.value.username;
    const code = this.forgetPasswordConfirmForm.value.code;
    const password = this.forgetPasswordConfirmForm.value.password;
    const hashedText = {
      username: username,
      hashpassword: CryptoJS.SHA256(password).toString(),
    }
    console.log(hashedText);
    this.http.post(this.validatePasswordURL, hashedText).subscribe(
      res => {
        try {
          console.log("RESPONSE FROM FORGOT PASSWORD:: ", res)
          this.authservice.forgetPasswordConfirm(this.username, code, password, hashedText);
          setTimeout(() => {
            this.authservice.forgotPasswordMessage.subscribe((message) => {
              console.log(message)

              if (message === 'password changed') {
                this.router.navigate(['default-layout/previous-analysis'])
              } else if (message === 'Error occured') {
                this.errorMessageForgot = message;
              }
              else if (message.includes("please don't use old password")) {
                this.errorMessageConfirm = "New password cannot be same as old password";
              }
            })
          }, 2000)
        }
        catch (error) {
          console.log(error)
        }
      },
      error => {
        console.log(error)
      }
    )


    // this.authservice.forgetPasswordConfirm(this.username, code, password, hashedText);
    // setTimeout(() => {
    //   this.authservice.forgotPasswordMessage.subscribe((message) => {
    //     console.log(message)

    //     if (message === 'password changed') {
    //       this.router.navigate(['default-layout/previous-analysis'])
    //     } else if (message === 'Error occured') {
    //       this.errorMessageForgot = message;
    //     }
    //     else if (message.includes("please don't use old password")) {
    //       this.errorMessageConfirm = "New password cannot be same as old password";
    //     }
    //   })
    // }, 2000)

  }


  UserChangePasswordConfirm() {
    const username = this.forgetPasswordForm.value.username;
    const code = this.ChangePasswordConfirmForm.value.code;
    const oldpassword = this.ChangePasswordConfirmForm.value.oldpassword;
    const password = this.ChangePasswordConfirmForm.value.confirmnewpassword;
    const hashedText = {
      username: username,
      oldhashpassword: CryptoJS.SHA256(oldpassword).toString(),
      hashpassword: CryptoJS.SHA256(password).toString(),
    }

    console.log(hashedText);

    this.http.post(this.validatePasswordURL, hashedText).subscribe(
      res => {
        try {
          console.log("RESPONSE FROM FORGOT PASSWORD:: ", res)
          this.authservice.forgetPasswordConfirm(this.username, code, password, hashedText);
          setTimeout(() => {
            this.authservice.forgotPasswordMessage.subscribe((message) => {
              console.log(message)

              if (message === 'password changed') {
                this.router.navigate(['default-layout/previous-analysis'])
              } else if (message === 'Error occured') {
                this.errorMessageForgot = message;
              }
              else if (message.includes("please don't use old password")) {
                this.errorMessageConfirm = "New password cannot be same as old password";
              }
            })
          }, 2000)
        }
        catch (error) {
          console.log(error)
        }
      },
      error => {
        console.log(error)
      }
    )


    // this.authservice.forgetPasswordConfirm(this.username, code, password,hashedText);
    // setTimeout(() => {
    //   this.authservice.forgotPasswordMessage.subscribe((message) => {
    //     console.log(message)

    //     if (message === 'password changed') {
    //       this.router.navigate(['default-layout/previous-analysis'])
    //     } else if(message === 'Error occured'){
    //       this.errorMessageForgot = message;
    //     }
    //     else if(message.includes("please don't use old password")){
    //       this.errorMessageConfirm = "New password cannot be same as old password";
    //     }
    //   })
    // }, 2000)

  }
}
