import { ASTWithName } from '@angular/compiler';
import { EventEmitter, Injectable, OnInit } from '@angular/core';
import { throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import { BehaviorSubject, EmptyError, Observable, observable, of } from 'rxjs';
import { tryCatch } from 'rxjs/internal-compatibility';
import { delay, timeInterval } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { environment } from '../../environments/environment';
import { callbackify } from 'util';
import Amplify, { Auth, Storage } from 'aws-amplify';
import { StorageService } from './storage.service';
import { HttpClient } from '@angular/common/http';
import { CustomRouteReuseStrategy } from '../shared/reuse-strategy';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AuthNewService {
  user = new Subject<CognitoUser>();
  currentUser!: CognitoUser;
  passwordChanged = new BehaviorSubject<boolean>(false);
  forgotPasswordCode = new BehaviorSubject<boolean>(false);
  forgotPasswordMessage = new BehaviorSubject<string>('');
  //  public continueUsername =new Subject<string>();
  //  public continuePassword = new Subject<string>(); 
  userGroup = new BehaviorSubject('');
  groups;
  forgotMessage;
  signinError = new Subject<string>();
  getCurrentLoginUser = new Subject<CognitoUser>();
  private userPool = new CognitoUserPool(environment.userPool);
  MfaUser = new Subject<CognitoUser>();
  userStorage
  RevokeTokenURL: string = environment.serverUrl + '/revoke_token';
  authUpdateURL: string = environment.serverUrl + '/first-auth-update'
  private failedLoginAttempts: { [username: string]: number } = {};
  private blockThreshold: number = 3;
  public accountBlocked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private router: Router,
    private http: HttpClient,
    private storageService: StorageService) { }

  //   ngOnInit(): void {
  //   throw new Error('Method not implemented.');
  // }

  private userlogInfo = null;

  get userLogInf() {
    return this.userlogInfo;
  }


  async continueSignIn(customAttributes) {
    await this.signIn(this.userlogInfo.uname, this.userlogInfo.pswrd, customAttributes);
  }
  // function to signin
  async signIn(username: string, password: string, customAttributes): Promise<void> {
    this.userlogInfo = {
      uname: username,
      pswrd: password,
    }
    await Auth.signIn(username, password, customAttributes)
      .then((user: any) => {
        console.log(user)
        this.currentUser = user;
        const accessToken = user.signInUserSession?.accessToken['jwtToken']
        if (user.attributes !== undefined) {
          this.storageService.set('username', user['username']);
          this.storageService.set('organization', user['attributes']['custom:organization']);
          this.storageService.set('group', user['attributes']['custom:group']);
          this.storageService.set('access', user['attributes']['custom:access']);
          this.storageService.set('name', user['attributes']['name']);
          this.storageService.set('refreshToken', JSON.stringify(user['signInUserSession']['refreshToken']))

        }

        if (accessToken) {
          const tokenParts = accessToken.split('.');
          const header = JSON.parse(atob(tokenParts[0]));
          const payload = JSON.parse(atob(tokenParts[1]));
          this.storageService.set('location', payload['location'])
        } else {
          console.error('No access token found');
        }

        this.user.next(user);
      })
      .catch((error) => {
        this.signinError.next(error);

      })

  }

  confirmCode(code: string, callback: (error: any, user?: any) => void) {
    Auth.confirmSignIn(this.currentUser, code)
      .then((user: any) => {
        this.currentUser = user;

        const accessToken = user.signInUserSession?.accessToken['jwtToken']
        if (accessToken) {
          const tokenParts = accessToken.split('.');
          const header = JSON.parse(atob(tokenParts[0]));
          const payload = JSON.parse(atob(tokenParts[1]));
          this.storageService.set('location', payload['location'])
        } else {
          console.error('No access token found');
        }

        this.user.next(user);
        callback(null, user);
      }).catch(error => {
        console.error(error);
        callback(error);
      });
  }
  // function to handle new password
  newPassword(password) {
    console.log(password)
    const customAttributes = {
      username: this.currentUser['username'],
      hashpassword: CryptoJS.SHA256(password).toString(),
    }
    console.log(customAttributes)
    Auth.completeNewPassword(this.currentUser, password)
      .then((res) => {
        console.log(res)
        this.http.post(this.authUpdateURL, customAttributes).subscribe(
          res => {
            try {

              console.log(res)
            }
            catch (error) {
              console.log(error)
            }
          }

        )
        this.passwordChanged.next(true);
      })
      .catch((error) => {
        console.log(error);
      })
  }


  getCurrentUser(User?) {
    Auth.currentAuthenticatedUser({
      bypassCache: true
    }).then(user => {
      this.getCurrentLoginUser.next(user);
    })
      .catch(err => {
        return err
      });

  }
  getMFACurrentUser() {
    Auth.currentAuthenticatedUser({
      bypassCache: true
    }).then(user => {
      this.storageService.set('username', user['username']);
      this.storageService.set('organization', user['attributes']['custom:organization']);
      this.storageService.set('group', user['attributes']['custom:group']);
      this.storageService.set('access', user['attributes']['custom:access']);
      this.storageService.set('name', user['attributes']['name']);
      this.storageService.set('refreshToken', JSON.stringify(user['signInUserSession']['refreshToken']))
      this.MfaUser.next(user);
    })
      .catch(err => {
        return err
      });
  }
  // function to signout
  async signout() {
    this.http.post(this.RevokeTokenURL, this.storageService.get('refreshToken')).subscribe(res => res)
    await Auth.currentAuthenticatedUser({
      bypassCache: true
    })
      .then(user => {

        if (user) {
          try {
            localStorage.clear();
            sessionStorage.clear();
            Auth.signOut({ global: true });
          }
          catch (error) {
            console.log('error occured while logout', error);
          }
          this.router.navigate(['extra-layout/home']).then(() => {
            window.location.reload();
          });;

        }
      })
      .catch(err => console.log(err));

  }

  forgetPassword(username: string) {
    Auth.forgotPassword(username)
      .then((data) => {

        this.forgotPasswordCode.next(true);
      })
      .catch((err) => console.log(err))
  }

  // Collect confirmation code and new password, then
  forgetPasswordConfirm(username, code, password, hashedText) {
    Auth.forgotPasswordSubmit(username, code, password, hashedText)
      .then((res) => {
        if (res === 'SUCCESS') {
          this.forgotMessage = 'password changed'
        } else {
          this.forgotMessage = 'Error occured'
        }
      })
      .catch((error) => {
        console.log(error.message)
        this.forgotMessage = error.message

      })
    setTimeout(() => {
      this.forgotPasswordMessage.next(this.forgotMessage);
    }, 2000);
  }

  getAuthenticatedUser() {
    return this.userPool.getCurrentUser();
  }

}
