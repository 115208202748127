export const environment = {
    production: false,
    serverUrl: 'https://pnd4pch85g.execute-api.us-east-2.amazonaws.com/dev',
    bucket: 'prod1redthreadqpcr-prod-upload',
    userPool: {
      UserPoolId: 'us-east-2_tQ0VyNUxB',
      ClientId: '491q6i3j4g0j4hugt9em683d0g',
      region: 'us-east-2'
    },
    identityPoolId: 'us-east-2:086d15a5-4410-4d50-a72e-1e6b434446ca',
    buckets: {
      'us-east-2': 'prod1redthreadqpcr-prod-upload'
    }, 
    modules: {
      key: 'analysisType',
      values: [
          {
            'name': 'qPCR',
            'desc': 'qPCR'
          }
      ]
  },
    defaultRegion: 'us-east-2',
    cognito: {
      identityPoolId: 'us-east-2:086d15a5-4410-4d50-a72e-1e6b434446ca', // REQUIRED - Amazon Cognito Identity Pool ID
      region: 'us-east-2', // REQUIRED - Amazon Cognito Region
      userPoolId: 'us-east-2_tQ0VyNUxB', // OPTIONAL - Amazon Cognito User Pool ID
      userPoolWebClientId: '491q6i3j4g0j4hugt9em683d0g', // OPTIONAL - Amazon Cognito Web Client ID
    },
    Storage: {
      AWSS3: {
        bucket: 'prod1redthreadqpcr-prod-upload',
        region: 'us-east-2', // OPTIONAL -  Amazon service region
      }
    }
  };
  const awsconfig = {
    'aws_project_region': 'us-east-2',
    'aws_cognito_region': 'us-east-2',
    'aws_user_pools_id': 'us-east-2_tQ0VyNUxB',
    'aws_user_pools_web_client_id': '491q6i3j4g0j4hugt9em683d0g',
    'aws_cognito_mfa_configuration': 'OPTIONAL',
  
  };
  export default awsconfig;
  